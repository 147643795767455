<template>
  <div
    style="height: calc(100vh - 64px)"
    class="w-full overflow-y-scroll slim-scrollbar"
  >
    <div class="flex items-center font-semibold text-black text-xl px-6 mt-6">
      <div>Catalogs</div>

      <div class="relative ml-8 w-1/3">
        <input
          v-model="searchString"
          class="
            w-full
            bg-gray-100
            h-10
            px-5
            pr-16
            rounded-lg
            text-sm
            focus:outline-none
          "
          type="search"
          name="search"
          placeholder="Search"
        />
        <button type="submit" class="absolute top-0 right-0 px-3 py-3">
          <svg
            class="text-gray-600 h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966"
            xml:space="preserve"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="px-6 mt-2 mb-8 text-sm">
      <h1>
        Welcome to our partner catalog collection. Follow the links below to
        view the catalogue of your choice.
      </h1>
    </div>
    <div class="grid p-6 grid-cols-1 md:grid-cols-5 lg:grid-cols-8 gap-8">
      <div
        v-for="folder in filteredFolders"
        :key="folder._id"
        class="bg-white text-xs text-center"
      >
        <p class="paper">
          <router-link :to="`/catalogs/${folder._id}`">
            <img
              class="
                mx-auto
                transform
                hover:shadow-xl hover:-translate-y-1 hover:scale-101
                duration-300
                ease-in-out
              "
              :src="`${api_host}/uploads/${folder.thumbnail}`"
            />
          </router-link>
        </p>
        <p class="mt-5 font-bold">{{ folder.brand }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CatalogFolders",
  data() {
    return {
      searchString: "",
      api_host: process.env.VUE_APP_API_HOST,
    };
  },
  methods: {
    ...mapActions(["getCatalogs", "getCatalogFolders"]),
  },
  computed: {
    ...mapGetters(["catalogFolders"]),
    filteredFolders() {
      this.searchString;

      if (!this.catalogFolders || !this.catalogFolders.length) {
        return [];
      }

      return this.catalogFolders.filter((folder) => {
        const regexp = new RegExp(this.searchString, "ig");
        return regexp.test(folder.brand);
      });
    },
  },
  mounted() {
    this.getCatalogFolders();
  },
  components: {},
};
</script>


<style scoped>
/* Diagonal stacked paper effect */
.paper {
  z-index: 2;
  /* Need position to allow stacking of pseudo-elements */
  position: relative;
}

.paper,
.paper::before,
.paper::after {
  /* Add shadow to distinguish sheets from one another */
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15);
}

.paper::before {
  background: rgb(245, 245, 245);
}

.paper::after {
  background: rgb(231, 231, 231);
}

.paper::before,
.paper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Second sheet of paper */
.paper::before {
  left: 7px;
  top: 5px;
  z-index: -1;
}

/* Third sheet of paper */
.paper::after {
  left: 12px;
  top: 10px;
  z-index: -2;
}
</style>


